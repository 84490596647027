import React from "react";
import { 
    StyledIndustryItemContainer,
    StyledIndustryItemTitle,
    StyledIndustryItemDescription
} from "../styled";

const IndustryItem = ({ title, description }) => {
  return (
    <StyledIndustryItemContainer>
      <StyledIndustryItemTitle>{title}</StyledIndustryItemTitle>
      <StyledIndustryItemDescription>
        {description}
      </StyledIndustryItemDescription>
    </StyledIndustryItemContainer>
  )
}

export default IndustryItem;
