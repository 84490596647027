import React from "react";
import IndustrySection from "../../../Common/CommonSections/IndustrySection";
import { StyledMarginWrapper } from "../../common-styles";
import { 
    INDUSTRY_ITEMS, 
    INDUSTRY_SECTION_TITLE, 
    INDUSTRY_SECTION_DESCRIPTION 
} from "../../constants";

const Industries = () => {
    return (
        <StyledMarginWrapper>
        <IndustrySection 
          title={INDUSTRY_SECTION_TITLE} 
          description={INDUSTRY_SECTION_DESCRIPTION}
          configuration={INDUSTRY_ITEMS}
          />
        </StyledMarginWrapper>
    )

};

export default Industries;