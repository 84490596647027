import React from "react";
import IndustryItem from "./IndustryItem";
import { StyledSectionTitle, StyledSectionDescription, StyledIndustryItemsContainer } from "./styled";

const IndustrySection = ({ title, description, configuration }) => {
    return (
        <div>
            <StyledSectionTitle>
                {title}
            </StyledSectionTitle>
            <StyledSectionDescription>
                {description}
            </StyledSectionDescription>
            <StyledIndustryItemsContainer>
                {configuration.map((props) => <IndustryItem {...props} />)}
            </StyledIndustryItemsContainer>
        </div>
    )

};

export default IndustrySection;