import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledSectionTitle = styled.h2`
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.438rem;
    color: #ffffff;
    
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 2rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
    }
`;

export const StyledSectionDescription = styled.p`
    color: rgba(255, 255, 255, 0.60);
    font-family: Nunito Sans;
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.8px;
`;

export const StyledIndustryItemTitle = styled.p`
    color: #C4B180;
    font-family: "Nunito Sans";
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.4px;
`;

export const StyledIndustryItemDescription = styled.p`
    color: rgba(255, 255, 255, 0.60);
    font-family: "Nunito Sans";
    font-size: 0.93rem;
    font-weight: 200;
    line-height: 150%;
    letter-spacing: 0.8px;
`;

export const StyledIndustryItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        grid-template-columns: 1fr;
  }
`;

export const StyledIndustryItemContainer = styled.div`
    max-width: 28rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        max-width: 100%;
  }
`;
